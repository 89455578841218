const Ably = require('ably')


export const USER_SEND_ORDERS = "user/sendOrders";
export const SERVER_TRIGGERED_SEND_ORDERS = "server/triggeredSendOrders"
export const SERVER_CREATED_ENVELOPE = "server/createdEnvelope"

export const USER_GET_PHYSICIANS = "user/getPhysicians";
export const SERVER_GOT_PHYSICIANS = "server/gotPhysicians";

export const USER_ADD_PHYSICIAN = "user/addPhysician";
export const SERVER_ADDED_PHYSICIAN = "server/addedPhysician"

export const USER_DELETE_PHYSICIAN = "user/deletePhysician"
export const SERVER_DELETED_PHYSICIAN = "server/deletedPhysician"

export class Domain {
    constructor(agencyId, store) {
        this.agencyId = agencyId;
        this.store = store;
    }

    async start() {
        console.log("domain:start")
        this.on(USER_GET_PHYSICIANS, async () => {
            const response = await fetch(`/api/agency/${this.agencyId}/physicians`)
            const physicians = await response.json()
            this.submitEvent(SERVER_GOT_PHYSICIANS, physicians)
        })
        this.on(USER_SEND_ORDERS, async (physician) => {
            const response = await fetch(`/api/agency/${this.agencyId}/physicians/sendOrders`, {
                method: "POST",
                body: JSON.stringify(physician),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            this.submitEvent(SERVER_TRIGGERED_SEND_ORDERS, physician)
        })
        this.on(USER_ADD_PHYSICIAN, async(physician) => {
            const response = await fetch(`/api/agency/${this.agencyId}/physicians`, {
                method: "POST",
                body: JSON.stringify(physician),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            this.submitEvent(SERVER_ADDED_PHYSICIAN, {})
        })
        this.on(USER_DELETE_PHYSICIAN, async(physicianToDelete) => {
            const oldPhysicians = this.store.getState().physicians
            const physicians = oldPhysicians.filter(p => p.email !== physicianToDelete.email)
            this.store.setState({physicians})
            const response = await fetch(`/api/agency/${this.agencyId}/physicians`, {
                method: "DELETE",
                body: JSON.stringify(physicianToDelete),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if(response.ok)
                this.submitEvent(SERVER_DELETED_PHYSICIAN, physicianToDelete)
        })
        this.on(SERVER_GOT_PHYSICIANS, (physicians) => {
            this.store.setState({physicians})
        })
        this.on(SERVER_DELETED_PHYSICIAN, (deletedPhysician) => {
            const oldPhysicians = this.store.getState().physicians
            const physicians = oldPhysicians.filter(p => p.email !== deletedPhysician.email)
            this.store.setState({physicians})
        })

        const config = await (await fetch(`/api/agency/${this.agencyId}/config`)).json()
        const client = new Ably.Realtime({key: config.ablyKey, })
        await client.channels.get(this.agencyId).subscribe((message) => {
            this.submitEvent(message.name, message.data.detail)
        })
    }

    submitEvent(eventType, data) {
        console.log('domain:submitEvent', eventType, data)
        window.dispatchEvent(new CustomEvent(eventType, {detail: data}))
    }

    on(eventType, callback) {
        console.log('domain:on', eventType)
        const wrappedCallback = (event) => {
            console.log("callback for", eventType)
            callback(event.detail)
        };
        window.addEventListener(eventType, wrappedCallback )
        return {eventType, callback: wrappedCallback}
    }

    off(unsubscription) {
        console.log('domain:off', unsubscription.eventType)
        window.removeEventListener(unsubscription.eventType, unsubscription.callback)
    }

    stop() {
        console.log("domain:stop")
    }
}

