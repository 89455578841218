import './App.css';
import {PhysiciansList} from "./PhysiciansList";
import {Link, useParams} from "react-router-dom";
import {Container, Divider, Fab, Grid, Typography} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useStore} from "./domains";



function App() {

    const {dashboardId} = useParams()
    const domain = window.domains.get(dashboardId)
    const physicians = useStore((state) => state.physicians)


  return (
      <Container maxWidth="md">
          <Grid container direction="row" sx={{paddingTop: '3rem', paddingBottom: '1rem'}}>
              <Grid xs={6} item sx={{display: 'flex', justifyContent: "flex-start"}}><Typography variant="h5">Physicians ({physicians.length})</Typography></Grid>
              <Grid xs={6} item sx={{display: 'flex', justifyContent: "flex-end"}}>
                  <Link to={'add-physician'}>
                      <Fab color="secondary" variant="extended" size="small" sx={{paddingX: '1rem'}}>
                          <PersonAddIcon sx={{"margin-right":"1rem"}}/>Add Physician
                      </Fab>
                  </Link>
              </Grid>
          </Grid>
          <Divider/>
          <PhysiciansList domain={domain} physicians={physicians}/>
      </Container>
  );
}

export default App;
