import {SendOrders} from "./SendOrders";
import {DeletePhysician} from "./DeletePhysician";
import {
    Card, CardActions,
    CardContent, CardHeader,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableRow, Typography
} from "@mui/material";

export function Physician({domain, physician}) {
    return <Grid xs={12} md={6}>
            <Card raised>
                <CardHeader
                    title={<Typography variant="h6" >Dr. {physician.firstName} {physician.lastName}</Typography>}/>
                <CardContent>
                    <Table   sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: 0
                            }
                        }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell sx={{textDecoration: 'none'}}>{physician.firstName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Last Name</TableCell>
                                <TableCell sx={{textDecoration: 'none'}}>{physician.lastName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell sx={{textDecoration: 'none'}}>{physician.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Envelope Per Patient</TableCell>
                                <TableCell sx={{textDecoration: 'none'}}>{physician.requiresDistinctEnvelopePerPatient || 'false'}</TableCell>
                            </TableRow>
                            {physician.ccs.map(cc=>
                                <>
                                    <TableRow>
                                        <TableCell>CC Name</TableCell>
                                        <TableCell>{cc.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>CC Email</TableCell>
                                        <TableCell sx={{textDecoration: 'none'}}>{cc.email}</TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
                <CardActions>
                        <Stack direction="column">
                            <SendOrders domain={domain} physician={physician}/>
                            <DeletePhysician domain={domain} physician={physician}/>
                        </Stack>
                </CardActions>
            </Card>
    </Grid>;
}