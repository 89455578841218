import {useState} from "react";
import {
    USER_DELETE_PHYSICIAN
} from "./Domain";
import {Button, CircularProgress} from "@mui/material";

export function DeletePhysician({domain, physician}) {
    const [status, setStatus] = useState("NOT_TRIGGERED")

    function deleteOrders() {
        const confirmed = window.confirm(`Are you sure you want to delete Dr. ${physician.firstName} ${physician.lastName}`);
        if(confirmed) {
            domain.submitEvent(USER_DELETE_PHYSICIAN, physician)
            setStatus("TRIGGERING")
        }
    }

    return <div>
        {(() => {
            switch (status) {
                case 'NOT_TRIGGERED':
                    return <Button color="error" onClick={deleteOrders}>Delete</Button>
                case 'TRIGGERING':
                    return <Button color="error" disabled><CircularProgress size="1rem" sx={{"margin-right":".5rem"}}/>Deleting</Button>
                case 'TRIGGERED':
                    return <Button color="error" disabled>Deleted</Button>
            }
        })()}
    </div>
}