import {Domain} from "./Domain";
import {create} from "zustand";

export const useStore = create(() => ({
    physicians:[],
    ongoingTransactions:[]
}))



class Domains {
    constructor() {
        this.domains = {}
    }

    get(agencyId) {
        if (!this.domains[agencyId]) {
            const domain = new Domain(agencyId, useStore);
            domain.start() //TODO: need to guarantee that start actually happens!!!
            this.domains[agencyId] = domain
            return domain
        }
        return this.domains[agencyId]
    }
}

window.domains = new Domains() //TODO: this is really weird
