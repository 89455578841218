import {useState} from "react";
import {SERVER_CREATED_ENVELOPE, SERVER_TRIGGERED_SEND_ORDERS, USER_SEND_ORDERS} from "./Domain";
import {useDomainSubscription} from "./useDomainSubscription";
import {Button, CircularProgress} from "@mui/material";

export function SendOrders({domain, physician}) {
    const [status, setStatus] = useState("NOT_TRIGGERED")
    const [numOrdersSent, setNumOrdersSent] = useState(0)
    useDomainSubscription(domain, SERVER_TRIGGERED_SEND_ORDERS, (event) => { //TODO: these listeners should get setup regardless
        if (event.email === physician.email) {
            setStatus("TRIGGERED");
        }
    });
    useDomainSubscription(domain, SERVER_CREATED_ENVELOPE, (event) => {
        if (event.email === physician.email) {
            setStatus("SENT")
            setNumOrdersSent(event.numOrders)
            setTimeout(() => {
                setStatus("NOT_TRIGGERED");
                setNumOrdersSent(0)
            }, 5000)
        }
    });

    function sendOrders() {
        domain.submitEvent(USER_SEND_ORDERS, physician)
        setStatus("TRIGGERING")
    }

    return <div>
        {(() => {
            switch (status) {
                case 'NOT_TRIGGERED':
                    return <Button onClick={sendOrders}>Send Orders</Button>
                case 'TRIGGERING':
                    return <Button disabled>Send Orders</Button>
                case 'TRIGGERED':
                    return <Button disabled><CircularProgress size="1rem" sx={{"margin-right":".5rem"}}/>Sending</Button>
                case 'SENT':
                    return <Button disabled>{numOrdersSent} Order(s) Sent</Button>
            }
        })()}
    </div>
}