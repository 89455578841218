import {createBrowserRouter, redirect} from "react-router-dom";
import Dashboard from "./App";
import {SERVER_ADDED_PHYSICIAN, SERVER_GOT_PHYSICIANS, USER_ADD_PHYSICIAN, USER_GET_PHYSICIANS} from "./Domain";
import {AddPhysician} from "./addPhysician";
import React from "react";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <div>submit your email to receive a magic link</div>,
    },
    {
        path: "/:dashboardId",
        element: <Dashboard/>,
        loader: async ({params}) => {
            return new Promise((resolve, reject) => {
                const domain = window.domains.get(params.dashboardId)
                const subscription = domain.on(SERVER_GOT_PHYSICIANS, (physicians) => {
                        domain.off(subscription)
                        resolve(null)
                    }
                )
                domain.submitEvent(USER_GET_PHYSICIANS, {})
            })
        }
    },
    {
        path: "/:dashboardId/add-physician",
        element: <AddPhysician/>,
        action: async ({request, params}) => {
            console.log("running action")
            return new Promise(async (resolve, reject) => {
                const domain = window.domains.get(params.dashboardId)
                const physician = Object.fromEntries(await request.formData())
                const subscription = domain.on(SERVER_ADDED_PHYSICIAN, () => {
                        domain.off(subscription)
                        resolve(redirect(`/${params.dashboardId}`));
                    }
                )
                domain.submitEvent(USER_ADD_PHYSICIAN, physician)
            })
        }
    }
]);