import * as PropTypes from "prop-types";
import {Physician} from "./Physician";
import {Grid} from "@mui/material";


Physician.propTypes = {
    p: PropTypes.bool,
    children: PropTypes.node
};

export function PhysiciansList({domain, physicians}) {
    return (
        <Grid container sx={{paddingTop: '2rem'}}>
            {physicians.map((p) => <Physician key={p.email} domain={domain} physician={p}/>)}
        </Grid>
    )
}