import React, {useState} from "react";
import {Form, useParams} from "react-router-dom";
import {SERVER_ADDED_PHYSICIAN} from "./Domain";
import {useDomainSubscription} from "./useDomainSubscription";
import {Button, CircularProgress, Container, FormControl, TextField} from "@mui/material";

export function AddPhysician() {

    const {dashboardId} = useParams()
    const [status, setStatus] = useState("NOT_TRIGGERED")
    const domain = window.domains.get(dashboardId);
    useDomainSubscription(domain, SERVER_ADDED_PHYSICIAN, () => {
        setStatus("TRIGGERED")
    })


    const onSubmit = (event) => {
        setStatus("TRIGGERING")
    };

    return <Container maxWidth="md">
        <Form onSubmit={onSubmit} method="post">
            <FormControl>
                <TextField label="First Name" type="text" name="firstName"/>
                <TextField label="Last Name" type="text" name="lastName"/>
                <TextField label="Email" type="email" name="email"/>
                <TextField label="CC Name" type="text" name="ccName"/>
                <TextField label="CC Email" type="email" name="cc"/>

                {(() => {
                    switch (status) {
                        case 'NOT_TRIGGERED':
                            return <Button type='submit'>Add Physician</Button>
                        case 'TRIGGERING':
                            return <Button disabled><CircularProgress size="1rem" sx={{"margin-right":".5rem"}}/>Adding</Button>
                        case 'TRIGGERED':
                            return <Button disabled>Added</Button>
                    }
                })()}

            </FormControl>
        </Form>
    </Container>
}

