import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import {responsiveFontSizes} from "@mui/material";

// A custom theme for this app
const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: '#46a0c8',
        },
        secondary: {
            main: '#999999',
        },
        error: {
            main: red.A400,
        },
    },
}));

export default theme;